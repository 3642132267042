









































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { LocaleMessageArray } from 'vue-i18n'

import { Inject } from '../../../../support'
import { Loader } from '../../../shared/molecules/Loader'
import StructureConfigurable from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { TabOption } from '../../../../dsl/abstract/components/Link/Link.contracts'

import { ILoyaltyService, LoyaltyServiceType } from '../../contracts/services'
import { LoyaltyPayload } from '../../contracts/loyalty'

import { benefitLogo } from '../../shared'
import { BenefitProgram, BenefitProgramAddon, BenefitProgramData, LinkData } from '../../contracts/programs'
import { BenefitProgramPreview } from '../../molecules/BenefitProgramPreview'
import { BenefitProgramResignForm } from '../../molecules/BenefitProgramResignForm'
import { BenefitProgramSelector } from '../BenefitProgramSelector'

import {
  BENEFIT_PROGRAMS_COMPONENT_KEY,
  defaultComponentConfig
} from './BenefitPrograms.config'
import { supportedBenefitPrograms } from './BenefitPrograms.helpers'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import MilesAndMoreAddon from './addons/MilesAndMoreAddon/MilesAndMoreAddon.vue'
import { DiscountsMixin } from '../../shared/mixins/discounts-mixin.component'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BenefitPrograms>({
  name: 'BenefitPrograms',
  components: {
    BenefitProgramPreview,
    BenefitProgramResignForm,
    BenefitProgramSelector,
    Loader
  },
  created (): void {
    this.config = this.getComponentConfig(BENEFIT_PROGRAMS_COMPONENT_KEY, defaultComponentConfig)

    this.init()
    this.composeBenefitsLinks()
  }
})
export class BenefitPrograms extends Mixins(DiscountsMixin, StructureConfigurable) {
  @Inject(LoyaltyServiceType)
  protected readonly loyaltyService!: ILoyaltyService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Array, required: false, default: null })
  public readonly errors?: string[] | null

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly showNone!: boolean

  public beingRemoved: BenefitProgramData | null = null
  public currentPrograms: BenefitProgram[] = []
  public fixedPrograms: BenefitProgram[] = []
  public isLoading: boolean = true
  public payload: LoyaltyPayload | null = null
  protected benefitsLinks: Partial<Record<BenefitProgram, LinkData>> | null = null

  public get hasPrograms (): boolean {
    return this.currentPrograms.length > 0
  }

  public get hasSelectedPrograms (): boolean {
    return this.selectedPrograms.length > 0
  }

  public get hasFaF (): boolean {
    return this.currentPrograms.includes(BenefitProgram.FriendsAndFamily)
  }

  public get programHeading (): string {
    if (this.isCheckout) {
      return 'programsAndBenefits'
    }

    return this.hasSelectedPrograms ? 'selectOtherProgram' : 'selectProgram'
  }

  public get programs (): BenefitProgramData[] {
    return supportedBenefitPrograms
      .map(p => ({
        id: p,
        name: this.getProgramLabel(p, 'title').toString(),
        benefits: this.getProgramBenefits(p),
        description: this.getProgramLabel(p, 'desc').toString(),
        logo: benefitLogo[p],
        link: this.benefitsLinks?.[p] ?? null,
        addon: this.getProgramAddon(p)
      }))
  }

  public get selectablePrograms (): BenefitProgram[] {
    let notSelected = supportedBenefitPrograms.filter(p => (
      !this.fixedPrograms.includes(p) && !this.currentPrograms.includes(p)
    ))

    // F&F wyklucza Kameleona
    if (this.hasFaF) {
      notSelected = notSelected.filter(p => p !== BenefitProgram.Kameleon)
    }

    return notSelected
  }

  public get selectableProgramsData (): BenefitProgramData[] {
    return this.programs.filter(p => this.selectablePrograms.includes(p.id))
  }

  public get selectedPrograms (): BenefitProgram[] {
    return this.currentPrograms.filter(p => !this.fixedPrograms.includes(p))
  }

  public get warning (): string | null {
    if (!this.hasSelectedPrograms) {
      return null
    }

    return this.$t(
      'front.loyalty.organisms.BenefitPrograms.warning',
      {
        new: this.getProgram(this.selectablePrograms[0])?.name,
        existing: this.getProgram(this.selectedPrograms[0])?.name
      }
    ).toString()
  }

  public getProgram (id: BenefitProgram): BenefitProgramData | undefined {
    return this.programs.find(p => p.id === id)
  }

  public getProgramLabel (program: BenefitProgram, key: 'title' | 'benefits' | 'desc') {
    const withFaF = this.hasFaF ? 'FaF' : ''
    const label = `front.loyalty.organisms.BenefitPrograms.programs.${program}.${key}`
    const discount = this.getDiscount(program)

    if (!this.hasFaF) {
      return this.$t(label, { discount })
    }

    const labelWithFaF = `${label}${withFaF}`
    const translation = this.$t(labelWithFaF, { discount })

    if (translation !== labelWithFaF) {
      return translation
    }

    return this.$t(label, { discount })
  }

  public getProgramAddon (p: BenefitProgram): BenefitProgramAddon | undefined {
    switch (p) {
      case BenefitProgram.MilesAndMore:
        return {
          component: MilesAndMoreAddon,
          props: {
            id: this.payload?.milesAndMore,
            points: this.payload?.milesAndMorePoints,
            pointsLimit: this.payload?.milesAndMorePointsLimit
          }
        }
      // case: Other programs...
      default:
        return undefined
    }
  }

  public getProgramBenefits (program: BenefitProgram): string[] | null {
    const benefitsLabel = this.getProgramLabel(program, 'benefits')

    if (typeof benefitsLabel === 'string') {
      return null
    }

    const discount = this.getDiscount(program)

    return Object.values(benefitsLabel as LocaleMessageArray)
      .map(label => label.replace('{discount}', discount).toString())
  }

  public async init (): Promise<void> {
    this.fixedPrograms = this.getConfigProperty('fixedPrograms', this.config)

    const loyalty = await this.loyaltyService.fetch()
    this.currentPrograms = loyalty.getCurrentPrograms()
    this.payload = {
      ...loyalty.getPayload(this.fixedPrograms),
      ...loyalty.getMilesAndMorePointsConfig()
    }

    if (this.currentPrograms.length) {
      this.$emit('selected', this.currentPrograms)
    }

    this.isLoading = false
  }

  public isRemovable (program: BenefitProgram): boolean {
    return !this.fixedPrograms.includes(program)
  }

  public onInitRemoval (programId: BenefitProgram): void {
    this.beingRemoved = this.programs.find(p => p.id === programId) ?? null
  }

  public onCancelRemoval (): void {
    this.beingRemoved = null
  }

  public onConfirmRemoval (program: BenefitProgram): void {
    this.currentPrograms = this.currentPrograms.filter(p => p !== program)
    this.beingRemoved = null
  }

  public onSuccess (program: BenefitProgram): void {
    this.$emit('selected', [...this.currentPrograms, program])
  }

  protected composeBenefitsLinks (): void {
    if (!this.siteService) {
      return
    }

    const siteLinks = this.siteService.getActiveSiteUrls()

    for (const key of Object.values(BenefitProgram)) {
      if (siteLinks[key]) {
        this.benefitsLinks = {
          ...this.benefitsLinks,
          [key]: {
            tabTarget: TabOption.Blank,
            target: siteLinks[key],
            label: key,
            title: key
          }
        }
      }
    }
  }
}

export default BenefitPrograms
